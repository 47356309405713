import React, {useEffect, useState} from 'react';
import logo from "../images/ds_energie_logo.png";

const SplashScreen = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100vw'
        }}>
            <div><img src={logo} alt="Logo" style={{maxHeight: '46vh', margin: 42}}/></div>
            <div className={"UI"}>
                <h2 style={{color: '#ffffff', letterSpacing: '5px', margin: '14px 10vw'}}>Chargement</h2>
            </div>
        </div>
    );
};

export default SplashScreen;
