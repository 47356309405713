import * as React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";


import SplashScreen from './components/SplashScreen';
import Menu from './components/Menu';
import Divination from './components/Divination';

import './App.css';

let props = {
    mainMenu: true,
    modal:null
}

function App() {

    document.getElementById("loadingScreen").style.display = "none";

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Menu data = {props}/>}/>
                    <Route path="Divination" element={<Divination />}/>
                    <Route path="SplashScreen" element={<SplashScreen />}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
