import * as React from "react";
import {Link} from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import Menu from '../components/Menu';


const style = {
    width: '50vw',
    marginTop: '20vh'
};

let props = {
    mainMenu: false,
    modal:null
}

const MenuInGame = () => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    props.modal = handleClose;

    return (
        <>
            <div style={{position: "absolute", zIndex: 5000, right: 20, marginTop: 10}} className={"bt"} onClick={handleOpen}>MENU</div>

            <Modal
                open={open}
                onClose={handleClose}
                onRequestClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Menu data = {props}/>
            </Modal>
        </>
    );
};

export default MenuInGame;
