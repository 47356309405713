import * as React from "react";
import {Link} from "react-router-dom";
import logo from '../images/ds_energie_logo.png';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';


const style = {
    width: '50vw',
    marginTop: '20vh'
};

const Menu = (props) => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            {props.data.mainMenu == true &&
            <div style={{display: 'flex', justifyContent: 'center', margin: '32px'}}>
                <h1 style={{color: '#ffffff', letterSpacing: '5px', margin: '14px 10vw'}}>Lorem Ispum</h1>
            </div>
            }

            <div className={"UI"}>
                {/* <Link to="/Divination" className={"bt"} style={{width: "60%"}} >Divination</Link>*/}
                <div className={"bt"} style={{width: "60%"}} onClick={handleOpen}>Divination</div>
                <Link to="/Divination" className={"bt"} style={{width: "60%"}}>Oui / Non</Link>
                <Link to="/about" className={"bt"} style={{width: "60%"}}>Description des cartes</Link>
                {props.data.mainMenu == false && <div className={"close-bt"} onClick={() => props.data.modal}>X</div>}
            </div>
            {props.data.mainMenu == true &&
            <div style={{display: 'flex', justifyContent: 'center', margin: '22px'}}>
                <img src={logo} alt="Logo" className={"logo-menu"}/>
            </div>
            }

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className={"UI"}>
                    <Link to="/Divination?q=conseil" className={"bt"} style={{width: "60%"}}>Conseil</Link>
                    <Link to="/Divination?q=divination" className={"bt"} style={{width: "60%"}}>Divination</Link>
                    <Link to="/Divination?q=divination_II" className={"bt"} style={{width: "60%"}}>Divination_II</Link>
                </Box>
            </Modal>
        </>
    );
};

export default Menu;
