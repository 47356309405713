/*
https://codepen.io/jhnsnc/pen/KNyOqV
 */

import React from "react";
import * as BABYLON from '@babylonjs/core';
import "@babylonjs/loaders/OBJ";
import {GridMaterial} from '@babylonjs/materials/grid'

import SceneComponent from "./SceneComponent"; // uses above component in same directory
// import SceneComponent from 'babylonjs-hook'; // if you install 'babylonjs-hook' NPM.
import TextureGen from "./TextureGen"

import RandomOrg from 'random-org';

// Medias
import M_lut from "../scenes/lut_Sprocket231.png";
import M_HDR from "../textures/satara_night_no_lamps_4k.hdr";
import Menu from "../components/MenuInGame";
import TextResult from "../components/TextResult";


const OnSceneReady = (scene) => {
    var Card0, plane;

    document.getElementById("loadingScreen").style.display = "flex";
    scene.clearColor = new BABYLON.Color4(0.0, 0.0, 0.0, 0);

    const Window_W = window.innerWidth;
    const Window_H = window.innerHeight;

    var A_cards = [];

    // conseil / tirage2 / tirage3

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const TirageType = urlParams.get('q');

    switch (TirageType) {

        case "conseil" :
            switch (true) {
                case (Window_W > 1020):
                    A_cards = [
                        [
                            [-2, 10, "", "", ""], [8, 2, "", "", ""], [14, 16, "", "", ""], [20, 3, "", "", ""], [29, 16, "", "", ""], [32, 1, "", "", ""], [42, 8, "", "", ""],
                        ],
                    ];
                    var CARDS = {
                        CardTextureBase: 1,
                        materialCard: [36],
                        textureContext: [36],
                        cardTurner: [36],
                        card_min: 0,
                        card_max: 6,
                        rnd_n: 1,
                        rnd_length: 7,
                        Tirage: 0,
                        Etape_tirage: 0,
                        Etape_tirage_current: 0,
                    }
                    break;
                case (Window_W < 1020):
                    A_cards = [
                        [
                            [0, 0, "", "", ""], [10, 0, "", "", ""], [20, 0, "", "", ""],
                            [0, 13, "", "", ""], [10, 13, "", "", ""], [20, 13, "", "", ""],
                            [0, 26, "", "", ""], [10, 26, "", "", ""], [20, 26, "", "", ""],
                            [10, 39, "", "", ""],
                        ],
                    ];
                    break;
            }
            break;

        case "divination" :
            switch (true) {
                case (Window_W > 1020):
                    A_cards = [
                        [
                            [0, 0, "", "", ""], [10, 0, "", "", ""], [20, 0, "", "", ""], [30, 0, "", "", ""], [40, 0, "", "", ""],
                            [0, 13, "", "", ""], [10, 13, "", "", ""], [20, 13, "", "", ""], [30, 13, "", "", ""], [40, 13, "", "", ""],
                        ],

                        [
                            [0, 0, "", "", ""], [10, 0, "", "", ""], [20, 0, "", "", ""], [30, 0, "", "", ""], [40, 0, "", "", ""],
                            [0, 13, "", "", ""], [10, 13, "", "", ""], [20, 13, "", "", ""], [30, 13, "", "", ""], [40, 13, "", "", ""],
                        ],

                        [
                            [0, 0, "", "", ""], [10, 0, "", "", ""], [20, 0, "", "", ""], [30, 0, "", "", ""], [40, 0, "", "", ""],
                            [0, 13, "", "", ""], [10, 13, "", "", ""], [20, 13, "", "", ""], [30, 13, "", "", ""], [40, 13, "", "", ""],
                        ],
                        [
                            [-2, 10, "", "", ""], [8, 2, "", "", ""], [14, 16, "", "", ""], [20, 3, "", "", ""], [29, 16, "", "", ""], [32, 1, "", "", ""], [42, 8, "", "", ""],
                        ],
                    ];
                    var CARDS = {
                        CardTextureBase: 1,
                        materialCard: [[6], [9], [9], [9]],
                        textureContext: [36],
                        cardTurner: [36],
                        card_min: 0,
                        card_max: 29,
                        rnd_n: 1,
                        rnd_length: 30,
                        Tirage: 0,
                        Etape_tirage: 3,
                        Etape_tirage_current: 0,
                        //CARDS.card_max + 1 / CARDS.Etape_tirage
                    }
                    break;
                case (Window_W < 1020):
                    A_cards = [
                        [
                            [0, 0, "", "", ""], [10, 0, "", "", ""], [20, 0, "", "", ""],
                            [0, 13, "", "", ""], [10, 13, "", "", ""], [20, 13, "", "", ""],
                            [0, 26, "", "", ""], [10, 26, "", "", ""], [20, 26, "", "", ""],
                            [10, 39, "", "", ""],
                        ],
                    ];
                    break;
            }
            break;
        default:
            window.location.href = "/";
    }


    //scene.imageProcessingConfiguration.exposure = 0.4;
    //scene.imageProcessingConfiguration.contrast = 1.2;


// Camera --------------------------------------------------------------------------------------------------------------

    //let camera = new BABYLON.ArcRotateCamera("Camera", -Math.PI / 4, Math.PI / 3.0, 60.0, BABYLON.Vector3.Zero(), scene);
    //let camPosX = (A_cards.length - 1) / 2 * 10;
    let camPosX;
    let camPosY;
    let camera;
    let postProcessOn;

    switch (true) {
        case (Window_W > 1020):
            camPosX = 20;
            camPosY = -12;
            camera = new BABYLON.UniversalCamera("UniversalCamera", new BABYLON.Vector3(camPosX, camPosY, -32), scene);
            camera.setTarget(new BABYLON.Vector3(20, 7, 0));
            postProcessOn = true;
            break;
        case (Window_W < 1020):
            camPosX = 10;
            camPosY = -8;
            camera = new BABYLON.UniversalCamera("UniversalCamera", new BABYLON.Vector3(camPosX, camPosY, -62), scene);
            camera.setTarget(new BABYLON.Vector3(10, 16, 0));
            postProcessOn = false;
            break;
    }

    //camera.attachControl(scene);


// Lights --------------------------------------------------------------------------------------------------------------

    let lightDome = new BABYLON.HemisphericLight("HemiLight", new BABYLON.Vector3(0, -20, -40), scene);
    lightDome.intensity = 1.8;

    var light = new BABYLON.DirectionalLight("dir01", new BABYLON.Vector3(0, -20, 20), scene);
    light.position = new BABYLON.Vector3(camPosX, camPosY + 50, -10);
    light.intensity = 3;

    var pointLight = new BABYLON.PointLight("pointLight", new BABYLON.Vector3(camPosX, -20, -60), scene);
    pointLight.diffuse = new BABYLON.Color3(1, 0.9, 0.6);
    pointLight.specular = new BABYLON.Color3(1, 0.9, 0.8);
    pointLight.intensity = 5;
    pointLight.radius = 200;

// Post Processing -----------------------------------------------------------------------------------------------------

    let lensEffect = new BABYLON.LensRenderingPipeline('lens', {
        edge_blur: 1.4,
        distortion: -0.5,
    }, scene, 1.0, camera);

    //let ssao = new BABYLON.SSAORenderingPipeline('ssaopipeline', scene, 0.005);

    let fxaa = new BABYLON.FxaaPostProcess("fxaa", 1.0, camera);

    let PostProcessLUT = new BABYLON.ColorCorrectionPostProcess("color_correction", M_lut, 1.0, camera);

    let postProcess = new BABYLON.ImageProcessingPostProcess("processing", 1.0, camera);
    postProcess.vignetteWeight = 1.6;
    postProcess.vignetteStretch = 0;
    postProcess.vignetteColor = new BABYLON.Color4(0.1, 0, 0.3, 0);
    postProcess.vignetteEnabled = postProcessOn
    postProcess.BloomEnabled = false;
    postProcess.blurWidth = 24;

// Shadows -------------------------------------------------------------------------------------------------------------

    var shadowGenerator = new BABYLON.ShadowGenerator(512, light);
    shadowGenerator.useContactHardeningShadow = true;
    shadowGenerator.contactHardeningLightSizeUVRatio = 0.075;

// Skybox --------------------------------------------------------------------------------------------------------------

    scene.environmentTexture = new BABYLON.HDRCubeTexture(M_HDR, scene, 256, false, true, false, true);

    let hdrTexture = new BABYLON.HDRCubeTexture(M_HDR, scene, 512, false, true, false, true);
    let hdrSkybox = BABYLON.Mesh.CreateBox("hdrSkyBox", 200.0, scene);
    let hdrSkyboxMaterial = new BABYLON.PBRMaterial("skyBox", scene);
    hdrSkyboxMaterial.backFaceCulling = false;
    hdrSkyboxMaterial.reflectionTexture = hdrTexture.clone();
    hdrSkyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
    //hdrSkyboxMaterial.microSurface = 0.74;
    hdrSkyboxMaterial.disableLighting = true;
    hdrSkybox.material = hdrSkyboxMaterial;
    hdrSkybox.infiniteDistance = true;
    hdrSkybox.needDepthPrePass = true;
    hdrSkybox.transparencyMode = BABYLON.PBRBaseMaterial.PBRMATERIAL_ALPHATEST;

// Animations     [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====]
    {

        var cardAnimeDeal = new BABYLON.Animation("tutoAnimation", "position.z", 25, BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
        // Animation keys
        let keysDeals = [];

        keysDeals.push({
            frame: 0,
            value: -100
        });
        keysDeals.push({
            frame: 30,
            value: -5
        });
        cardAnimeDeal.setKeys(keysDeals);

        var cardAnime = new BABYLON.Animation("tutoAnimation", "rotation.y", 25, BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
        // Animation keys
        let keys = [];

        keys.push({
            frame: 30,
            value: -3
        });
        keys.push({
            frame: 60,
            value: 0.0
        });
        cardAnime.setKeys(keys);

        var cardAnimeFront = new BABYLON.Animation("tutoAnimation", "rotation.x", 25, BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
        // Animation keys
        let keysFront = [];

        keysFront.push({
            frame: 30,
            value: 0
        });
        keysFront.push({
            frame: 60,
            value: -0.5
        });
        cardAnimeFront.setKeys(keysFront);

        var cardAnimeUp = new BABYLON.Animation("tutoAnimation", "position.z", 25, BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
        // Animation keys
        let keysAnimeUp = [];

        keysAnimeUp.push({
            frame: 30,
            value: 0.0
        });
        keysAnimeUp.push({
            frame: 60,
            value: -5
        });
        cardAnimeUp.setKeys(keysAnimeUp);

        var textCardAnime = new BABYLON.Animation("tutoAnimation", "position.z", 25, BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
        // Animation keys
        let keysTextCardAnime = [];

        keysTextCardAnime.push({
            frame: 20,
            value: -16.0
        });
        keysTextCardAnime.push({
            frame: 30,
            value: -6.0
        });
        textCardAnime.setKeys(keysTextCardAnime);

        var textCardAnimeFront = new BABYLON.Animation("tutoAnimation", "rotation.x", 25, BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
        // Animation keys
        let keysTextCardFront = [];

        keysTextCardFront.push({
            frame: 20,
            value: 0
        });
        keysTextCardFront.push({
            frame: 30,
            value: -0.5
        });
        textCardAnimeFront.setKeys(keysTextCardFront);

        var textCardAnime_alpha = new BABYLON.Animation("tutoAnimation", "visibility", 25, BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);

        let keysTextCardAnime_alpha = [];
        keysTextCardAnime_alpha.push({
            frame: 20,
            value: 0.0
        });
        keysTextCardAnime_alpha.push({
            frame: 30,
            value: 1.0
        });
        textCardAnime_alpha.setKeys(keysTextCardAnime_alpha);
    }


    // Materials ------------------------------------------------------------------------------------------------ */
    // ---------------------------------------------------------------------------------------------------------- */
    // ---------------------------------------------------------------------------------------------------------- */

    var Board = new BABYLON.PBRMaterial("pbr", scene);
    Board.backFaceCulling = false;
    Board.metallic = 0.3;
    Board.roughness = 0.5;
    Board.reflectionTexture = hdrTexture;
    Board.directIntensity = 1.8;
    Board.environmentIntensity = 1.0;
    Board.brdf.useEnergyConservation = true;
    Board.brdf.useSphericalHarmonics = true;
    Board.forceIrradianceInFragment = true;
    Board.microSurface = 0.5;
    Board.reflectivityColor = new BABYLON.Color3(0.747, 0.592, 0.611);
    Board.sheen.isEnabled = true;
    Board.sheen.intensity = 0.5;
    Board.sheen.color = new BABYLON.Color3(0.1, 0.1, 0.1);
    Board.albedoTexture = new BABYLON.Texture("./assets/Image_bg.png", scene, true, false);
    Board.bumpTexture = new BABYLON.Texture("./assets/Image_bg_bump.png", scene, true, false);
    Board.freeze();

    var CardTextureBase = new BABYLON.PBRMaterial("card", scene)
    CardTextureBase.reflectionTexture = hdrTexture;
    CardTextureBase.environmentIntensity = 2;
    CardTextureBase.directIntensity = 2.0;
    CardTextureBase.albedoTexture = new BABYLON.Texture("./assets/defaultMat_Base_Color.png", scene, true, true);
    CardTextureBase.metallicTexture = new BABYLON.Texture("./assets/MR_map.png", scene, true, true);
    CardTextureBase.bumpTexture = new BABYLON.Texture("./assets/defaultMat_Normal_OpenGL_2.png", scene, true, true);
    CardTextureBase.useRoughnessFromMetallicTextureAlpha = false;
    CardTextureBase.useRoughnessFromMetallicTextureGreen = true;
    CardTextureBase.useMetallnessFromMetallicTextureRed = true;

    var CardTextureBase_dark = new BABYLON.PBRMaterial("card", scene)
    CardTextureBase_dark.reflectionTexture = hdrTexture;
    CardTextureBase_dark.environmentIntensity = 2;
    CardTextureBase_dark.directIntensity = 2.2;
    CardTextureBase_dark.albedoTexture = new BABYLON.Texture("./assets/defaultMat_Base_Color_dark.png", scene, true, true);
    CardTextureBase_dark.metallicTexture = new BABYLON.Texture("./assets/MR_map.png", scene, true, true);
    CardTextureBase_dark.bumpTexture = new BABYLON.Texture("./assets/defaultMat_Normal_OpenGL_2.png", scene, true, true);
    CardTextureBase_dark.useRoughnessFromMetallicTextureAlpha = false;
    CardTextureBase_dark.useRoughnessFromMetallicTextureGreen = true;
    CardTextureBase_dark.useMetallnessFromMetallicTextureRed = true;


    {

        let color3 = [0.01, 0.01, 0.01];
        let metallic_t = 0.7;
        let directIntensity = 1.5;
        let environmentIntensity = 1;

        switch (TirageType) {
            case "conseil" :
                CARDS.materialCard[0] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[0].metallic = metallic_t;
                CARDS.materialCard[0].roughness = 0.1;
                CARDS.materialCard[0].reflectionTexture = hdrTexture;
                CARDS.materialCard[0].environmentIntensity = environmentIntensity;
                CARDS.materialCard[0].directIntensity = directIntensity;
                CARDS.materialCard[0].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_0.png", scene, true, true);
                CARDS.materialCard[0].albedoTexture.hasAlpha = true;
                // CARDS.materialCard[0].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[0].alpha = 1;
                CARDS.materialCard[0].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND;
                CARDS.materialCard[0].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[0].useRadianceOverAlpha = false;
                CARDS.materialCard[0].useSpecularOverAlpha = false;
                CARDS.materialCard[0].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[0][0][4] = "Action";

                CARDS.materialCard[1] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[1].metallic = metallic_t;
                CARDS.materialCard[1].roughness = 0.1;
                CARDS.materialCard[1].reflectionTexture = hdrTexture;
                CARDS.materialCard[1].environmentIntensity = environmentIntensity;
                CARDS.materialCard[1].directIntensity = directIntensity;
                CARDS.materialCard[1].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_1.png", scene, true, true);
                CARDS.materialCard[1].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[1].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[1].alpha = 1;
                CARDS.materialCard[1].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND;
                CARDS.materialCard[1].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[1].useRadianceOverAlpha = false;
                CARDS.materialCard[1].useSpecularOverAlpha = false;
                CARDS.materialCard[1].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[0][1][4] = "Espoir";

                CARDS.materialCard[2] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[2].metallic = metallic_t;
                CARDS.materialCard[2].roughness = 0.1;
                CARDS.materialCard[2].reflectionTexture = hdrTexture;
                CARDS.materialCard[2].environmentIntensity = environmentIntensity;
                CARDS.materialCard[2].directIntensity = directIntensity;
                CARDS.materialCard[2].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_2.png", scene, true, true);
                CARDS.materialCard[2].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[2].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[2].alpha = 1;
                CARDS.materialCard[2].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[2].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[2].useRadianceOverAlpha = false;
                CARDS.materialCard[2].useSpecularOverAlpha = false;
                CARDS.materialCard[2].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[0][2][4] = "Attente";

                CARDS.materialCard[3] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[3].metallic = metallic_t;
                CARDS.materialCard[3].roughness = 0.1;
                CARDS.materialCard[3].reflectionTexture = hdrTexture;
                CARDS.materialCard[3].environmentIntensity = environmentIntensity;
                CARDS.materialCard[3].directIntensity = directIntensity;
                CARDS.materialCard[3].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_3.png", scene, true, true);
                CARDS.materialCard[3].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[3].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[3].alpha = 1;
                CARDS.materialCard[3].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[3].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[3].useRadianceOverAlpha = false;
                CARDS.materialCard[3].useSpecularOverAlpha = false;
                CARDS.materialCard[3].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[0][3][4] = "Karma";

                CARDS.materialCard[4] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[4].metallic = metallic_t;
                CARDS.materialCard[4].roughness = 0.1;
                CARDS.materialCard[4].reflectionTexture = hdrTexture;
                CARDS.materialCard[4].environmentIntensity = environmentIntensity;
                CARDS.materialCard[4].directIntensity = directIntensity;
                CARDS.materialCard[4].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_4.png", scene, true, true);
                CARDS.materialCard[4].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[4].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[4].alpha = 1;
                CARDS.materialCard[4].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[4].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[4].useRadianceOverAlpha = false;
                CARDS.materialCard[4].useSpecularOverAlpha = false;
                CARDS.materialCard[4].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[0][4][4] = "Mon choix";

                CARDS.materialCard[5] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[5].metallic = metallic_t;
                CARDS.materialCard[5].roughness = 0.1;
                CARDS.materialCard[5].reflectionTexture = hdrTexture;
                CARDS.materialCard[5].environmentIntensity = environmentIntensity;
                CARDS.materialCard[5].directIntensity = directIntensity;
                CARDS.materialCard[5].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_5.png", scene, true, true);
                CARDS.materialCard[5].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[5].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[5].alpha = 1;
                CARDS.materialCard[5].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[5].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[5].useRadianceOverAlpha = false;
                CARDS.materialCard[5].useSpecularOverAlpha = false;
                CARDS.materialCard[5].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[0][5][4] = "Communication";

                CARDS.materialCard[6] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[6].metallic = metallic_t;
                CARDS.materialCard[6].roughness = 0.1;
                CARDS.materialCard[6].reflectionTexture = hdrTexture;
                CARDS.materialCard[6].environmentIntensity = environmentIntensity;
                CARDS.materialCard[6].directIntensity = directIntensity;
                CARDS.materialCard[6].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_6.png", scene, true, true);
                CARDS.materialCard[6].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[6].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[6].alpha = 1;
                CARDS.materialCard[6].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[6].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[6].useRadianceOverAlpha = false;
                CARDS.materialCard[6].useSpecularOverAlpha = false;
                CARDS.materialCard[6].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[0][6][4] = "Lâcher prise";

                break;
        }

        switch (TirageType) {
            case "divination" :
                CARDS.materialCard[0][0] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[0][0].metallic = metallic_t;
                CARDS.materialCard[0][0].roughness = 0.1;
                CARDS.materialCard[0][0].reflectionTexture = hdrTexture;
                CARDS.materialCard[0][0].environmentIntensity = environmentIntensity;
                CARDS.materialCard[0][0].directIntensity = directIntensity;
                CARDS.materialCard[0][0].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_7.png", scene, true, true);
                CARDS.materialCard[0][0].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[7].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[0][0].alpha = 1;
                CARDS.materialCard[0][0].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[0][0].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[0][0].useRadianceOverAlpha = false;
                CARDS.materialCard[0][0].useSpecularOverAlpha = false;
                CARDS.materialCard[0][0].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[0][0][4] = "action";

                CARDS.materialCard[0][1] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[0][1].metallic = metallic_t;
                CARDS.materialCard[0][1].roughness = 0.1;
                CARDS.materialCard[0][1].reflectionTexture = hdrTexture;
                CARDS.materialCard[0][1].environmentIntensity = environmentIntensity;
                CARDS.materialCard[0][1].directIntensity = directIntensity;
                CARDS.materialCard[0][1].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_8.png", scene, true, true);
                CARDS.materialCard[0][1].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[1].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[0][1].alpha = 1;
                CARDS.materialCard[0][1].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[0][1].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[0][1].useRadianceOverAlpha = false;
                CARDS.materialCard[0][1].useSpecularOverAlpha = false;
                CARDS.materialCard[0][1].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[0][1][4] = "action";

                CARDS.materialCard[0][2] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[0][2].metallic = metallic_t;
                CARDS.materialCard[0][2].roughness = 0.1;
                CARDS.materialCard[0][2].reflectionTexture = hdrTexture;
                CARDS.materialCard[0][2].environmentIntensity = environmentIntensity;
                CARDS.materialCard[0][2].directIntensity = directIntensity;
                CARDS.materialCard[0][2].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[0][2].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[0][2].alpha = 1;
                CARDS.materialCard[0][2].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[0][2].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[0][2].useRadianceOverAlpha = false;
                CARDS.materialCard[0][2].useSpecularOverAlpha = false;
                CARDS.materialCard[0][2].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[0][2][4] = "action";

                CARDS.materialCard[0][3] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[0][3].metallic = metallic_t;
                CARDS.materialCard[0][3].roughness = 0.1;
                CARDS.materialCard[0][3].reflectionTexture = hdrTexture;
                CARDS.materialCard[0][3].environmentIntensity = environmentIntensity;
                CARDS.materialCard[0][3].directIntensity = directIntensity;
                CARDS.materialCard[0][3].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[0][3].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[0][3].alpha = 1;
                CARDS.materialCard[0][3].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[0][3].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[0][3].useRadianceOverAlpha = false;
                CARDS.materialCard[0][3].useSpecularOverAlpha = false;
                CARDS.materialCard[0][3].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[0][3][4] = "action";

                CARDS.materialCard[0][4] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[0][4].metallic = metallic_t;
                CARDS.materialCard[0][4].roughness = 0.1;
                CARDS.materialCard[0][4].reflectionTexture = hdrTexture;
                CARDS.materialCard[0][4].environmentIntensity = environmentIntensity;
                CARDS.materialCard[0][4].directIntensity = directIntensity;
                CARDS.materialCard[0][4].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[0][4].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[0][4].alpha = 1;
                CARDS.materialCard[0][4].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[0][4].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[0][4].useRadianceOverAlpha = false;
                CARDS.materialCard[0][4].useSpecularOverAlpha = false;
                CARDS.materialCard[0][4].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[0][4][4] = "action";

                CARDS.materialCard[0][5] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[0][5].metallic = metallic_t;
                CARDS.materialCard[0][5].roughness = 0.1;
                CARDS.materialCard[0][5].reflectionTexture = hdrTexture;
                CARDS.materialCard[0][5].environmentIntensity = environmentIntensity;
                CARDS.materialCard[0][5].directIntensity = directIntensity;
                CARDS.materialCard[0][5].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[0][5].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[0][5].alpha = 1;
                CARDS.materialCard[0][5].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[0][5].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[0][5].useRadianceOverAlpha = false;
                CARDS.materialCard[0][5].useSpecularOverAlpha = false;
                CARDS.materialCard[0][5].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[0][5][4] = "action";

                CARDS.materialCard[0][6] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[0][6].metallic = metallic_t;
                CARDS.materialCard[0][6].roughness = 0.1;
                CARDS.materialCard[0][6].reflectionTexture = hdrTexture;
                CARDS.materialCard[0][6].environmentIntensity = environmentIntensity;
                CARDS.materialCard[0][6].directIntensity = directIntensity;
                CARDS.materialCard[0][6].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[0][6].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[0][6].alpha = 1;
                CARDS.materialCard[0][6].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[0][6].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[0][6].useRadianceOverAlpha = false;
                CARDS.materialCard[0][6].useSpecularOverAlpha = false;
                CARDS.materialCard[0][6].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[0][6][4] = "action";

                CARDS.materialCard[0][7] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[0][7].metallic = metallic_t;
                CARDS.materialCard[0][7].roughness = 0.1;
                CARDS.materialCard[0][7].reflectionTexture = hdrTexture;
                CARDS.materialCard[0][7].environmentIntensity = environmentIntensity;
                CARDS.materialCard[0][7].directIntensity = directIntensity;
                CARDS.materialCard[0][7].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[0][7].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[0][7].alpha = 1;
                CARDS.materialCard[0][7].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[0][7].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[0][7].useRadianceOverAlpha = false;
                CARDS.materialCard[0][7].useSpecularOverAlpha = false;
                CARDS.materialCard[0][7].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[0][7][4] = "action";

                CARDS.materialCard[0][8] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[0][8].metallic = metallic_t;
                CARDS.materialCard[0][8].roughness = 0.1;
                CARDS.materialCard[0][8].reflectionTexture = hdrTexture;
                CARDS.materialCard[0][8].environmentIntensity = environmentIntensity;
                CARDS.materialCard[0][8].directIntensity = directIntensity;
                CARDS.materialCard[0][8].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[0][8].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[0][8].alpha = 1;
                CARDS.materialCard[0][8].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[0][8].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[0][8].useRadianceOverAlpha = false;
                CARDS.materialCard[0][8].useSpecularOverAlpha = false;
                CARDS.materialCard[0][8].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[0][8][4] = "action";

                CARDS.materialCard[0][9] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[0][9].metallic = metallic_t;
                CARDS.materialCard[0][9].roughness = 0.1;
                CARDS.materialCard[0][9].reflectionTexture = hdrTexture;
                CARDS.materialCard[0][9].environmentIntensity = environmentIntensity;
                CARDS.materialCard[0][9].directIntensity = directIntensity;
                CARDS.materialCard[0][9].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[0][9].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[0][9].alpha = 1;
                CARDS.materialCard[0][9].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[0][9].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[0][9].useRadianceOverAlpha = false;
                CARDS.materialCard[0][9].useSpecularOverAlpha = false;
                CARDS.materialCard[0][9].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[0][9][4] = "action";


                CARDS.materialCard[1][0] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[1][0].metallic = metallic_t;
                CARDS.materialCard[1][0].roughness = 0.1;
                CARDS.materialCard[1][0].reflectionTexture = hdrTexture;
                CARDS.materialCard[1][0].environmentIntensity = environmentIntensity;
                CARDS.materialCard[1][0].directIntensity = directIntensity;
                CARDS.materialCard[1][0].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[1][0].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[1][0].alpha = 1;
                CARDS.materialCard[1][0].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[1][0].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[1][0].useRadianceOverAlpha = false;
                CARDS.materialCard[1][0].useSpecularOverAlpha = false;
                CARDS.materialCard[1][0].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[1][0][4] = "action";

                CARDS.materialCard[1][1] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[1][1].metallic = metallic_t;
                CARDS.materialCard[1][1].roughness = 0.1;
                CARDS.materialCard[1][1].reflectionTexture = hdrTexture;
                CARDS.materialCard[1][1].environmentIntensity = environmentIntensity;
                CARDS.materialCard[1][1].directIntensity = directIntensity;
                CARDS.materialCard[1][1].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[1][1].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[1][1].alpha = 1;
                CARDS.materialCard[1][1].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[1][1].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[1][1].useRadianceOverAlpha = false;
                CARDS.materialCard[1][1].useSpecularOverAlpha = false;
                CARDS.materialCard[1][1].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[1][1][4] = "action";

                CARDS.materialCard[1][2] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[1][2].metallic = metallic_t;
                CARDS.materialCard[1][2].roughness = 0.1;
                CARDS.materialCard[1][2].reflectionTexture = hdrTexture;
                CARDS.materialCard[1][2].environmentIntensity = environmentIntensity;
                CARDS.materialCard[1][2].directIntensity = directIntensity;
                CARDS.materialCard[1][2].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[1][2].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[1][2].alpha = 1;
                CARDS.materialCard[1][2].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[1][2].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[1][2].useRadianceOverAlpha = false;
                CARDS.materialCard[1][2].useSpecularOverAlpha = false;
                CARDS.materialCard[1][2].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[1][2][4] = "action";

                CARDS.materialCard[1][3] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[1][3].metallic = metallic_t;
                CARDS.materialCard[1][3].roughness = 0.1;
                CARDS.materialCard[1][3].reflectionTexture = hdrTexture;
                CARDS.materialCard[1][3].environmentIntensity = environmentIntensity;
                CARDS.materialCard[1][3].directIntensity = directIntensity;
                CARDS.materialCard[1][3].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[1][3].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[1][3].alpha = 1;
                CARDS.materialCard[1][3].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[1][3].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[1][3].useRadianceOverAlpha = false;
                CARDS.materialCard[1][3].useSpecularOverAlpha = false;
                CARDS.materialCard[1][3].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[1][3][4] = "action";

                CARDS.materialCard[1][4] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[1][4].metallic = metallic_t;
                CARDS.materialCard[1][4].roughness = 0.1;
                CARDS.materialCard[1][4].reflectionTexture = hdrTexture;
                CARDS.materialCard[1][4].environmentIntensity = environmentIntensity;
                CARDS.materialCard[1][4].directIntensity = directIntensity;
                CARDS.materialCard[1][4].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[1][4].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[1][4].alpha = 1;
                CARDS.materialCard[1][4].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[1][4].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[1][4].useRadianceOverAlpha = false;
                CARDS.materialCard[1][4].useSpecularOverAlpha = false;
                CARDS.materialCard[1][4].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[1][4][4] = "action";

                CARDS.materialCard[1][5] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[1][5].metallic = metallic_t;
                CARDS.materialCard[1][5].roughness = 0.1;
                CARDS.materialCard[1][5].reflectionTexture = hdrTexture;
                CARDS.materialCard[1][5].environmentIntensity = environmentIntensity;
                CARDS.materialCard[1][5].directIntensity = directIntensity;
                CARDS.materialCard[1][5].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[1][5].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[1][5].alpha = 1;
                CARDS.materialCard[1][5].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[1][5].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[1][5].useRadianceOverAlpha = false;
                CARDS.materialCard[1][5].useSpecularOverAlpha = false;
                CARDS.materialCard[1][5].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[1][5][4] = "action";

                CARDS.materialCard[1][6] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[1][6].metallic = metallic_t;
                CARDS.materialCard[1][6].roughness = 0.1;
                CARDS.materialCard[1][6].reflectionTexture = hdrTexture;
                CARDS.materialCard[1][6].environmentIntensity = environmentIntensity;
                CARDS.materialCard[1][6].directIntensity = directIntensity;
                CARDS.materialCard[1][6].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[1][6].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[1][6].alpha = 1;
                CARDS.materialCard[1][6].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[1][6].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[1][6].useRadianceOverAlpha = false;
                CARDS.materialCard[1][6].useSpecularOverAlpha = false;
                CARDS.materialCard[1][6].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[1][6][4] = "action";

                CARDS.materialCard[1][7] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[1][7].metallic = metallic_t;
                CARDS.materialCard[1][7].roughness = 0.1;
                CARDS.materialCard[1][7].reflectionTexture = hdrTexture;
                CARDS.materialCard[1][7].environmentIntensity = environmentIntensity;
                CARDS.materialCard[1][7].directIntensity = directIntensity;
                CARDS.materialCard[1][7].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[1][7].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[1][7].alpha = 1;
                CARDS.materialCard[1][7].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[1][7].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[1][7].useRadianceOverAlpha = false;
                CARDS.materialCard[1][7].useSpecularOverAlpha = false;
                CARDS.materialCard[1][7].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[1][7][4] = "action";

                CARDS.materialCard[1][8] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[1][8].metallic = metallic_t;
                CARDS.materialCard[1][8].roughness = 0.1;
                CARDS.materialCard[1][8].reflectionTexture = hdrTexture;
                CARDS.materialCard[1][8].environmentIntensity = environmentIntensity;
                CARDS.materialCard[1][8].directIntensity = directIntensity;
                CARDS.materialCard[1][8].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[1][8].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[1][8].alpha = 1;
                CARDS.materialCard[1][8].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[1][8].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[1][8].useRadianceOverAlpha = false;
                CARDS.materialCard[1][8].useSpecularOverAlpha = false;
                CARDS.materialCard[1][8].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[1][8][4] = "action";

                CARDS.materialCard[1][9] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[1][9].metallic = metallic_t;
                CARDS.materialCard[1][9].roughness = 0.1;
                CARDS.materialCard[1][9].reflectionTexture = hdrTexture;
                CARDS.materialCard[1][9].environmentIntensity = environmentIntensity;
                CARDS.materialCard[1][9].directIntensity = directIntensity;
                CARDS.materialCard[1][9].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[1][9].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[1][9].alpha = 1;
                CARDS.materialCard[1][9].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[1][9].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[1][9].useRadianceOverAlpha = false;
                CARDS.materialCard[1][9].useSpecularOverAlpha = false;
                CARDS.materialCard[1][9].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[1][9][4] = "action";

                CARDS.materialCard[2][0] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[2][0].metallic = metallic_t;
                CARDS.materialCard[2][0].roughness = 0.1;
                CARDS.materialCard[2][0].reflectionTexture = hdrTexture;
                CARDS.materialCard[2][0].environmentIntensity = environmentIntensity;
                CARDS.materialCard[2][0].directIntensity = directIntensity;
                CARDS.materialCard[2][0].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[2][0].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[2][0].alpha = 1;
                CARDS.materialCard[2][0].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[2][0].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[2][0].useRadianceOverAlpha = false;
                CARDS.materialCard[2][0].useSpecularOverAlpha = false;
                CARDS.materialCard[2][0].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[2][0][4] = "action";

                CARDS.materialCard[2][1] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[2][1].metallic = metallic_t;
                CARDS.materialCard[2][1].roughness = 0.1;
                CARDS.materialCard[2][1].reflectionTexture = hdrTexture;
                CARDS.materialCard[2][1].environmentIntensity = environmentIntensity;
                CARDS.materialCard[2][1].directIntensity = directIntensity;
                CARDS.materialCard[2][1].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[2][1].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[2][1].alpha = 1;
                CARDS.materialCard[2][1].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[2][1].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[2][1].useRadianceOverAlpha = false;
                CARDS.materialCard[2][1].useSpecularOverAlpha = false;
                CARDS.materialCard[2][1].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[2][1][4] = "action";

                CARDS.materialCard[2][2] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[2][2].metallic = metallic_t;
                CARDS.materialCard[2][2].roughness = 0.1;
                CARDS.materialCard[2][2].reflectionTexture = hdrTexture;
                CARDS.materialCard[2][2].environmentIntensity = environmentIntensity;
                CARDS.materialCard[2][2].directIntensity = directIntensity;
                CARDS.materialCard[2][2].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[2][2].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[2][2].alpha = 1;
                CARDS.materialCard[2][2].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[2][2].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[2][2].useRadianceOverAlpha = false;
                CARDS.materialCard[2][2].useSpecularOverAlpha = false;
                CARDS.materialCard[2][2].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[2][2][4] = "action";

                CARDS.materialCard[2][3] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[2][3].metallic = metallic_t;
                CARDS.materialCard[2][3].roughness = 0.1;
                CARDS.materialCard[2][3].reflectionTexture = hdrTexture;
                CARDS.materialCard[2][3].environmentIntensity = environmentIntensity;
                CARDS.materialCard[2][3].directIntensity = directIntensity;
                CARDS.materialCard[2][3].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[2][3].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[2][3].alpha = 1;
                CARDS.materialCard[2][3].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[2][3].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[2][3].useRadianceOverAlpha = false;
                CARDS.materialCard[2][3].useSpecularOverAlpha = false;
                CARDS.materialCard[2][3].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[2][3][4] = "action";

                CARDS.materialCard[2][4] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[2][4].metallic = metallic_t;
                CARDS.materialCard[2][4].roughness = 0.1;
                CARDS.materialCard[2][4].reflectionTexture = hdrTexture;
                CARDS.materialCard[2][4].environmentIntensity = environmentIntensity;
                CARDS.materialCard[2][4].directIntensity = directIntensity;
                CARDS.materialCard[2][4].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[2][4].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[2][4].alpha = 1;
                CARDS.materialCard[2][4].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[2][4].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[2][4].useRadianceOverAlpha = false;
                CARDS.materialCard[2][4].useSpecularOverAlpha = false;
                CARDS.materialCard[2][4].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[2][4][4] = "action";

                CARDS.materialCard[2][5] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[2][5].metallic = metallic_t;
                CARDS.materialCard[2][5].roughness = 0.1;
                CARDS.materialCard[2][5].reflectionTexture = hdrTexture;
                CARDS.materialCard[2][5].environmentIntensity = environmentIntensity;
                CARDS.materialCard[2][5].directIntensity = directIntensity;
                CARDS.materialCard[2][5].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[2][5].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[2][5].alpha = 1;
                CARDS.materialCard[2][5].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[2][5].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[2][5].useRadianceOverAlpha = false;
                CARDS.materialCard[2][5].useSpecularOverAlpha = false;
                CARDS.materialCard[2][5].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[2][5][4] = "action";

                CARDS.materialCard[2][6] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[2][6].metallic = metallic_t;
                CARDS.materialCard[2][6].roughness = 0.1;
                CARDS.materialCard[2][6].reflectionTexture = hdrTexture;
                CARDS.materialCard[2][6].environmentIntensity = environmentIntensity;
                CARDS.materialCard[2][6].directIntensity = directIntensity;
                CARDS.materialCard[2][6].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[2][6].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[2][6].alpha = 1;
                CARDS.materialCard[2][6].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[2][6].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[2][6].useRadianceOverAlpha = false;
                CARDS.materialCard[2][6].useSpecularOverAlpha = false;
                CARDS.materialCard[2][6].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[2][6][4] = "action";

                CARDS.materialCard[2][7] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[2][7].metallic = metallic_t;
                CARDS.materialCard[2][7].roughness = 0.1;
                CARDS.materialCard[2][7].reflectionTexture = hdrTexture;
                CARDS.materialCard[2][7].environmentIntensity = environmentIntensity;
                CARDS.materialCard[2][7].directIntensity = directIntensity;
                CARDS.materialCard[2][7].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[2][7].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[2][7].alpha = 1;
                CARDS.materialCard[2][7].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[2][7].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[2][7].useRadianceOverAlpha = false;
                CARDS.materialCard[2][7].useSpecularOverAlpha = false;
                CARDS.materialCard[2][7].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[2][7][4] = "action";

                CARDS.materialCard[2][8] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[2][8].metallic = metallic_t;
                CARDS.materialCard[2][8].roughness = 0.1;
                CARDS.materialCard[2][8].reflectionTexture = hdrTexture;
                CARDS.materialCard[2][8].environmentIntensity = environmentIntensity;
                CARDS.materialCard[2][8].directIntensity = directIntensity;
                CARDS.materialCard[2][8].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[2][8].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[2][8].alpha = 1;
                CARDS.materialCard[2][8].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[2][8].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[2][8].useRadianceOverAlpha = false;
                CARDS.materialCard[2][8].useSpecularOverAlpha = false;
                CARDS.materialCard[2][8].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[2][8][4] = "action";

                CARDS.materialCard[2][9] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[2][9].metallic = metallic_t;
                CARDS.materialCard[2][9].roughness = 0.1;
                CARDS.materialCard[2][9].reflectionTexture = hdrTexture;
                CARDS.materialCard[2][9].environmentIntensity = environmentIntensity;
                CARDS.materialCard[2][9].directIntensity = directIntensity;
                CARDS.materialCard[2][9].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_9.png", scene, true, true);
                CARDS.materialCard[2][9].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[9].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[2][9].alpha = 1;
                CARDS.materialCard[2][9].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[2][9].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[2][9].useRadianceOverAlpha = false;
                CARDS.materialCard[2][9].useSpecularOverAlpha = false;
                CARDS.materialCard[2][9].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[2][9][4] = "action";


                CARDS.materialCard[3][0] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[3][0].metallic = metallic_t;
                CARDS.materialCard[3][0].roughness = 0.1;
                CARDS.materialCard[3][0].reflectionTexture = hdrTexture;
                CARDS.materialCard[3][0].environmentIntensity = environmentIntensity;
                CARDS.materialCard[3][0].directIntensity = directIntensity;
                CARDS.materialCard[3][0].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_0.png", scene, true, true);
                CARDS.materialCard[3][0].albedoTexture.hasAlpha = true;
                // CARDS.materialCard[0].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[3][0].alpha = 1;
                CARDS.materialCard[3][0].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND;
                CARDS.materialCard[3][0].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[3][0].useRadianceOverAlpha = false;
                CARDS.materialCard[3][0].useSpecularOverAlpha = false;
                CARDS.materialCard[3][0].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[3][0][4] = "Action";

                CARDS.materialCard[3][1] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[3][1].metallic = metallic_t;
                CARDS.materialCard[3][1].roughness = 0.1;
                CARDS.materialCard[3][1].reflectionTexture = hdrTexture;
                CARDS.materialCard[3][1].environmentIntensity = environmentIntensity;
                CARDS.materialCard[3][1].directIntensity = directIntensity;
                CARDS.materialCard[3][1].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_1.png", scene, true, true);
                CARDS.materialCard[3][1].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[1].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[3][1].alpha = 1;
                CARDS.materialCard[3][1].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND;
                CARDS.materialCard[3][1].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[3][1].useRadianceOverAlpha = false;
                CARDS.materialCard[3][1].useSpecularOverAlpha = false;
                CARDS.materialCard[3][1].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[3][1][4] = "Espoir";

                CARDS.materialCard[3][2] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[3][2].metallic = metallic_t;
                CARDS.materialCard[3][2].roughness = 0.1;
                CARDS.materialCard[3][2].reflectionTexture = hdrTexture;
                CARDS.materialCard[3][2].environmentIntensity = environmentIntensity;
                CARDS.materialCard[3][2].directIntensity = directIntensity;
                CARDS.materialCard[3][2].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_2.png", scene, true, true);
                CARDS.materialCard[3][2].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[2].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[3][2].alpha = 1;
                CARDS.materialCard[3][2].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[3][2].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[3][2].useRadianceOverAlpha = false;
                CARDS.materialCard[3][2].useSpecularOverAlpha = false;
                CARDS.materialCard[3][2].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[3][2][4] = "Attente";

                CARDS.materialCard[3][3] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[3][3].metallic = metallic_t;
                CARDS.materialCard[3][3].roughness = 0.1;
                CARDS.materialCard[3][3].reflectionTexture = hdrTexture;
                CARDS.materialCard[3][3].environmentIntensity = environmentIntensity;
                CARDS.materialCard[3][3].directIntensity = directIntensity;
                CARDS.materialCard[3][3].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_3.png", scene, true, true);
                CARDS.materialCard[3][3].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[3].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[3][3].alpha = 1;
                CARDS.materialCard[3][3].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[3][3].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[3][3].useRadianceOverAlpha = false;
                CARDS.materialCard[3][3].useSpecularOverAlpha = false;
                CARDS.materialCard[3][3].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[3][3][4] = "Karma";

                CARDS.materialCard[3][4] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[3][4].metallic = metallic_t;
                CARDS.materialCard[3][4].roughness = 0.1;
                CARDS.materialCard[3][4].reflectionTexture = hdrTexture;
                CARDS.materialCard[3][4].environmentIntensity = environmentIntensity;
                CARDS.materialCard[3][4].directIntensity = directIntensity;
                CARDS.materialCard[3][4].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_4.png", scene, true, true);
                CARDS.materialCard[3][4].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[4].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[3][4].alpha = 1;
                CARDS.materialCard[3][4].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[3][4].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[3][4].useRadianceOverAlpha = false;
                CARDS.materialCard[3][4].useSpecularOverAlpha = false;
                CARDS.materialCard[3][4].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[3][4][4] = "Mon choix";

                CARDS.materialCard[3][5] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[3][5].metallic = metallic_t;
                CARDS.materialCard[3][5].roughness = 0.1;
                CARDS.materialCard[3][5].reflectionTexture = hdrTexture;
                CARDS.materialCard[3][5].environmentIntensity = environmentIntensity;
                CARDS.materialCard[3][5].directIntensity = directIntensity;
                CARDS.materialCard[3][5].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_5.png", scene, true, true);
                CARDS.materialCard[3][5].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[5].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[3][5].alpha = 1;
                CARDS.materialCard[3][5].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[3][5].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[3][5].useRadianceOverAlpha = false;
                CARDS.materialCard[3][5].useSpecularOverAlpha = false;
                CARDS.materialCard[3][5].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[3][5][4] = "Communication";

                CARDS.materialCard[3][6] = new BABYLON.PBRMaterial("card", scene);
                CARDS.materialCard[3][6].metallic = metallic_t;
                CARDS.materialCard[3][6].roughness = 0.1;
                CARDS.materialCard[3][6].reflectionTexture = hdrTexture;
                CARDS.materialCard[3][6].environmentIntensity = environmentIntensity;
                CARDS.materialCard[3][6].directIntensity = directIntensity;
                CARDS.materialCard[3][6].albedoTexture = new BABYLON.Texture("./assets/Image_set_0_text_6.png", scene, true, true);
                CARDS.materialCard[3][6].albedoTexture.hasAlpha = true;
                //CARDS.materialCard[6].bumpTexture = new BABYLON.Texture("./assets/Image_set_0_text_n_5.png", scene, true, true);
                CARDS.materialCard[3][6].alpha = 1;
                CARDS.materialCard[3][6].transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND
                CARDS.materialCard[3][6].useAlphaFromAlbedoTexture = true;
                CARDS.materialCard[3][6].useRadianceOverAlpha = false;
                CARDS.materialCard[3][6].useSpecularOverAlpha = false;
                CARDS.materialCard[3][6].emissiveColor = new BABYLON.Color3(color3[0], color3[1], color3[2]);
                A_cards[3][6][4] = "Lâcher prise";

                break;
        }
    }


    // RANDOM.ORG [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====]

    var resRandomArray;
    var random = new RandomOrg({apiKey: 'f9b0c084-9a44-47c2-aa8a-c0b7d77372eb'});


    random.generateIntegerSequences({

        // The number of random integer sequences to generate (valid values: [1-10000]).
        n: CARDS.rnd_n,
        // The length of the sequences to generate (valid values: [1-10000]).
        // Alternatively an array of `n` lengths if you need sequences of varying
        // lengths (the sum of all lengths must be in the range: [1-10000]).
        length: CARDS.rnd_length,
        // Lower bound for random integers (valid values: [-1e9 - 1e9] and `< max`).
        min: CARDS.card_min,
        // Upper bound for random integers (valid values: [-1e9 - 1e9] and `> min`).
        max: CARDS.card_max,

        // Whether or not the generated numbers can contain duplicates (default: true).
        replacement: false,
        // The base of the generated numbers (default: 10; valid values: 2, 8, 10 or 16).
        // If `base` is any value other than 10, the generated numbers will be returned as strings.
        base: 10
    })
        .then(function (result) {
            resRandomArray = result.random.data[0];
            //console.log(resRandomArray)


            // Import Mesh [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====]

            BABYLON.SceneLoader.ImportMesh("", "./assets/", "Card.obj", scene, (Card0) => {
                Card0[1].setEnabled(false);
                Card0[1].scaling = new BABYLON.Vector3(1.1, 1.1, 1.1);

                const plane = BABYLON.MeshBuilder.CreatePlane("plane", {
                    height: 9,
                    width: 8,
                    sideOrientation: BABYLON.Mesh.DOUBLESIDE
                });
                plane.setEnabled(false);

                //console.log(A_cards[i][0])


                init_Cards(A_cards, CARDS, Card0, resRandomArray, TirageType, CardTextureBase, CardTextureBase_dark, shadowGenerator, cardAnimeDeal, cardAnimeUp, cardAnimeFront, cardAnime, textCardAnime, textCardAnime_alpha, textCardAnimeFront, plane)

            });

            // Background [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====]

            let card_BG;
            switch (true) {
                case (Window_W > 1020):
                    card_BG = BABYLON.MeshBuilder.CreateBox("card_BG", {
                        height: 100,
                        width: 170,
                        depth: 0.25,
                        updatable: true
                    });
                    break;
                case (Window_W < 1020):
                    card_BG = BABYLON.MeshBuilder.CreateBox("card_BG", {
                        height: 180,
                        width: 120,
                        depth: 0.25,
                        updatable: true
                    });
                    break;
            }

            card_BG.position.z = 1;
            card_BG.position.x = 20;
            card_BG.position.y = 12;
            card_BG.material = Board;
            card_BG.rotation.y = Math.PI;
            card_BG.scaling = new BABYLON.Vector3(0.6, 0.5, 0.5);
            card_BG.receiveShadows = true;

        });



    // Particles
    const particleSystem = new BABYLON.ParticleSystem("particles", 256);
    particleSystem.particleTexture = new BABYLON.Texture("./assets/flare00.png");
    particleSystem.targetStopDuration = 0.12;
    particleSystem.minSize = 2;
    particleSystem.maxSize = 16;
    particleSystem.color1 = new BABYLON.Color4(0.9, 0.9, 0.3, 1.0);
    particleSystem.color2 = new BABYLON.Color4(1.0, 0.6, 0.3, 0.6);
    particleSystem.color3 = new BABYLON.Color4(0.1, 1.0, 0.3, 0.7);
    particleSystem.color4 = new BABYLON.Color4(0.1, 0.6, 1.0, 1.0);
    particleSystem.colorDead = new BABYLON.Color4(0, 0, 0, 0.0);
    particleSystem.minEmitPower = 2;
    particleSystem.maxEmitPower = 24;
    particleSystem.gravity = new BABYLON.Vector3(0, -9.81, 0);
    particleSystem.emitRate = 128;
    particleSystem.createSphereEmitter(2);
    particleSystem.blendMode = BABYLON.ParticleSystem.BLENDMODE_ADD;


//       [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====]


    scene.onPointerDown = function (evt, pickResult) {

        switch (TirageType) {
            case "conseil" :
                if (CARDS.Tirage < 1) {
                    if (pickResult.hit) {
                        if (pickResult.pickedMesh.name !== "card_BG") {
                            for (let y = 0; y < CARDS.card_max + 1; y++) {
                                if (A_cards[CARDS.Etape_tirage_current][y][2].name === pickResult.pickedMesh.name) {
                                    if (CARDS.cardTurner[y] !== true) {
                                        console.log(y)
                                        console.log(A_cards[CARDS.Etape_tirage_current][y][2].name)
                                        console.log(A_cards[CARDS.Etape_tirage_current][y][4])
                                        console.log(y)
                                        //pickResult.pickedMesh.position.z = -5;
                                        scene.beginAnimation(pickResult.pickedMesh, 30, 60, false);
                                        //pickResult.pickedMesh.rotateAround(new BABYLON.Vector3(pickResult.pickedMesh.position.x, 0, 0), BABYLON.Axis.Y, Math.PI);

                                        A_cards[CARDS.Etape_tirage_current][y][3].setEnabled(true);
                                        scene.beginAnimation(A_cards[CARDS.Etape_tirage_current][y][3], 0, 30, false);

                                        pointLight.position = new BABYLON.Vector3(pickResult.pickedMesh.position.x, pickResult.pickedMesh.position.y + 6, -8);
                                        particleSystem.reset();
                                        particleSystem.emitter = new BABYLON.Vector3(pickResult.pickedMesh.position.x, pickResult.pickedMesh.position.y, -4);
                                        particleSystem.start();

                                        document.getElementById("text_res_final").style.display = "flex";
                                        document.getElementById("text_res_text_final").innerHTML = A_cards[CARDS.Etape_tirage_current][y][4];


                                        CARDS.cardTurner[y] = true;
                                        CARDS.Tirage++;

                                        const button = document.getElementById('restart_game');

                                        button.addEventListener('click', function handleClick() {
                                            if (A_cards[CARDS.Etape_tirage_current][0][3]) {
                                                for (let i = CARDS.card_min; i < CARDS.card_max + 1; i++) {
                                                    A_cards[CARDS.Etape_tirage_current][i][2].dispose();
                                                    A_cards[CARDS.Etape_tirage_current][i][3].dispose();
                                                }
                                            }
                                            document.getElementById("text_res_final").style.display = "none";
                                            document.getElementById("loadingScreen").style.display = "flex";

                                            // Import Mesh [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====]

                                            BABYLON.SceneLoader.ImportMesh("", "./assets/", "Card.obj", scene, (Card0) => {
                                                Card0[1].setEnabled(false);
                                                Card0[1].scaling = new BABYLON.Vector3(1.1, 1.1, 1.1);

                                                const plane = BABYLON.MeshBuilder.CreatePlane("plane", {
                                                    height: 9,
                                                    width: 8,
                                                    sideOrientation: BABYLON.Mesh.DOUBLESIDE
                                                });
                                                plane.setEnabled(false);

                                                //console.log(A_cards[i][0])

                                                init_Cards(A_cards, CARDS, Card0, resRandomArray, TirageType, CardTextureBase, CardTextureBase_dark, shadowGenerator, cardAnimeDeal, cardAnimeUp, cardAnimeFront, cardAnime, textCardAnime, textCardAnime_alpha, textCardAnimeFront, plane)
                                                document.getElementById("loadingScreen").style.display = "none";

                                            })

                                        });


                                    }
                                }
                            }
                            //CARDS.cardTurner[]
                            //console.log(A_cards[pickResult.pickedMesh.position.x][pickResult.pickedMesh.position.y])
                        }
                    }
                }
                break
            case "divination" :
                if (CARDS.Tirage < 4) {
                    if (pickResult.hit) {
                        if (pickResult.pickedMesh.name !== "card_BG") {
                            for (let y = 0; y < CARDS.card_max + 1; y++) {
                                if (A_cards[CARDS.Etape_tirage_current][y][2].name === pickResult.pickedMesh.name) {
                                    if (CARDS.cardTurner[y] !== true) {

                                        scene.beginAnimation(pickResult.pickedMesh, 30, 60, false);

                                        A_cards[CARDS.Etape_tirage_current][y][3].setEnabled(true);
                                        scene.beginAnimation(A_cards[CARDS.Etape_tirage_current][y][3], 0, 30, false);

                                        pointLight.position = new BABYLON.Vector3(pickResult.pickedMesh.position.x, pickResult.pickedMesh.position.y + 6, -8);
                                        particleSystem.reset();
                                        particleSystem.emitter = new BABYLON.Vector3(pickResult.pickedMesh.position.x, pickResult.pickedMesh.position.y, -4);
                                        particleSystem.start();

                                        if (CARDS.Tirage > 7) {
                                            document.getElementById("text_res_final").style.display = "flex";
                                            document.getElementById("text_res_text_final").innerHTML = A_cards[CARDS.Etape_tirage_current][y][4];
                                        }

                                        CARDS.cardTurner[y] = true;
                                        CARDS.Tirage++;

                                        const button = document.getElementById('restart_game');

                                        if (CARDS.Tirage > 2) {

                                            CARDS.Tirage = 0;

                                            if (A_cards[CARDS.Etape_tirage_current][0][3]) {
                                                for (let i = CARDS.card_min; i < CARDS.card_max + 1; i++) {
                                                    A_cards[CARDS.Etape_tirage_current][i][2].dispose();
                                                    A_cards[CARDS.Etape_tirage_current][i][3].dispose();
                                                }
                                            }
                                            document.getElementById("text_res_final").style.display = "none";
                                            document.getElementById("loadingScreen").style.display = "flex";

                                            // Import Mesh [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====] [====]

                                            BABYLON.SceneLoader.ImportMesh("", "./assets/", "Card.obj", scene, (Card0) => {
                                                Card0[1].setEnabled(false);
                                                Card0[1].scaling = new BABYLON.Vector3(1.1, 1.1, 1.1);

                                                const plane = BABYLON.MeshBuilder.CreatePlane("plane", {
                                                    height: 9,
                                                    width: 8,
                                                    sideOrientation: BABYLON.Mesh.DOUBLESIDE
                                                });
                                                plane.setEnabled(false);


                                                init_Cards(A_cards, CARDS, Card0, resRandomArray, TirageType, CardTextureBase, CardTextureBase_dark, shadowGenerator, cardAnimeDeal, cardAnimeUp, cardAnimeFront, cardAnime, textCardAnime, textCardAnime_alpha, textCardAnimeFront, plane)
                                                document.getElementById("loadingScreen").style.display = "none";

                                            })


                                        }

                                    }
                                }
                            }
                        }
                    }
                }
                break;
        }


    };

// executeWhenReady ---------------------------------------///---------------------------------------- executeWhenReady

    scene.executeWhenReady(() => {
        document.getElementById("loadingScreen").style.display = "none";
    });


// registerBeforeRender -----------------------------------///------------------------------------ registerBeforeRender

    scene.registerBeforeRender(function () {

    });



// FUNCTIONS -----------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------

    function init_Cards(A_cards, CARDS, Card0, resRandomArray, TirageType, CardTextureBase, CardTextureBase_dark, shadowGenerator, cardAnimeDeal, cardAnimeUp, cardAnimeFront, cardAnime, textCardAnime, textCardAnime_alpha, textCardAnimeFront, plane) {

        switch (TirageType) {
            case "conseil" :
                for (let i = CARDS.card_min; i < CARDS.card_max + 1; i++) {
                    let newInstance = Card0[1].clone("card_" + i);
                    newInstance.setEnabled(true);
                    newInstance.position = new BABYLON.Vector3(A_cards[CARDS.Etape_tirage_current][resRandomArray[i]][0], A_cards[CARDS.Etape_tirage_current][resRandomArray[i]][1]);
                    A_cards[CARDS.Etape_tirage_current][i][2] = newInstance;

                    newInstance.material = CardTextureBase;

                    shadowGenerator.getShadowMap().renderList.push(newInstance);
                    newInstance.rotation.y = Math.PI;
                    let rnd_pos = (Math.random() < 0.5 ? -1 : 1) * 0.05
                    newInstance.rotation.z = rnd_pos;
                    newInstance.animations.push(cardAnimeDeal);
                    newInstance.animations.push(cardAnimeUp);
                    newInstance.animations.push(cardAnimeFront);
                    newInstance.animations.push(cardAnime);

                    let newInstance_plane = plane.clone("plane_" + i);
                    A_cards[CARDS.Etape_tirage_current][i][3] = newInstance_plane;
                    newInstance_plane.material = CARDS.materialCard[CARDS.Etape_tirage_current][i];
                    newInstance_plane.position = new BABYLON.Vector3(A_cards[CARDS.Etape_tirage_current][resRandomArray[i]][0], A_cards[CARDS.Etape_tirage_current][resRandomArray[i]][1]);
                    newInstance_plane.position.z = -11;
                    newInstance_plane.rotation.z = rnd_pos;
                    newInstance_plane.scaling = new BABYLON.Vector3(1.5, 1.5, 1.5);
                    newInstance_plane.animations.push(textCardAnime);
                    newInstance_plane.animations.push(textCardAnime_alpha);
                    newInstance_plane.animations.push(textCardAnimeFront);
                    newInstance_plane.setEnabled(false);

                }
                break;
            case "divination" :
                let tirage_current = Math.floor((CARDS.card_max + 1) / CARDS.Etape_tirage);
                //alert(tirage_current);

                for (let i = CARDS.Etape_tirage_current + CARDS.card_min; i < CARDS.Etape_tirage_current + tirage_current-1; i++) {

                    let newInstance = Card0[1].clone("card_" + i);
                    newInstance.setEnabled(true);
                    newInstance.position = new BABYLON.Vector3(A_cards[CARDS.Etape_tirage_current][resRandomArray[i]][0], A_cards[CARDS.Etape_tirage_current][resRandomArray[i]][1]);
                    console.log("1");
                    A_cards[CARDS.Etape_tirage_current][i][2] = newInstance;
                    console.log("2");
                    newInstance.material = CardTextureBase_dark;
                    console.log("3");
                    shadowGenerator.getShadowMap().renderList.push(newInstance);
                    newInstance.rotation.y = Math.PI;
                    let rnd_pos = (Math.random() < 0.5 ? -1 : 1) * 0.05
                    newInstance.rotation.z = rnd_pos;
                    newInstance.animations.push(cardAnimeDeal);
                    newInstance.animations.push(cardAnimeUp);
                    newInstance.animations.push(cardAnimeFront);
                    newInstance.animations.push(cardAnime);
                    console.log("5");

                    let newInstance_plane = plane.clone("plane_" + i);
                    A_cards[CARDS.Etape_tirage_current][i][3] = newInstance_plane;
                    newInstance_plane.material = CARDS.materialCard[CARDS.Etape_tirage_current][i];
                    newInstance_plane.position = new BABYLON.Vector3(A_cards[CARDS.Etape_tirage_current][resRandomArray[i]][0], A_cards[CARDS.Etape_tirage_current][resRandomArray[i]][1]);
                    newInstance_plane.position.z = -11;
                    newInstance_plane.rotation.z = rnd_pos;
                    newInstance_plane.scaling = new BABYLON.Vector3(1.5, 1.5, 1.5);
                    newInstance_plane.animations.push(textCardAnime);
                    newInstance_plane.animations.push(textCardAnime_alpha);
                    newInstance_plane.animations.push(textCardAnimeFront);
                    newInstance_plane.setEnabled(false);
                    console.log("10 -");
                    console.log(i);
                    console.log(CARDS.Etape_tirage_current + tirage_current-1);
                }
                CARDS.Etape_tirage_current++;
                alert(CARDS.Etape_tirage_current);
                break;
        }
    }


};



const onRender = (scene) => {
    //scene.getEngine()
};

export default () => (
    <div className={"UICards"}>
        <Menu/>
        <SceneComponent antialias onSceneReady={OnSceneReady} onRender={onRender} id="renderCanvas"/>
    </div>
)

